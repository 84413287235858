import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createVNode as _createVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-f782f20e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["aria-label"]
const _hoisted_2 = { class: "p-grid" }
const _hoisted_3 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_4 = { for: "language" }
const _hoisted_5 = {
  key: 0,
  class: "p-invalid"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_CardComponent = _resolveComponent("CardComponent")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_CardComponent, {
    cardTitle: _ctx.$t('common.settings'),
    "edit-mode": _ctx.editMode
  }, {
    headingActions: _withCtx(() => [
      _withDirectives((_openBlock(), _createBlock(_component_Button, {
        class: _normalizeClass(["p-button-rounded p-button-only-icon p-button-only-icon--dark", _ctx.editMode ? 'p-disabled' : '']),
        onClick: _ctx.onEditForm
      }, {
        default: _withCtx(() => [
          _createElementVNode("i", {
            class: "icon-edit",
            "aria-label": _ctx.$t('common.edit'),
            "aria-hidden": "true"
          }, null, 8, _hoisted_1)
        ]),
        _: 1
      }, 8, ["class", "onClick"])), [
        [
          _directive_tooltip,
          _ctx.$t('common.edit'),
          void 0,
          { bottom: true }
        ]
      ])
    ]),
    cardContent: _withCtx(() => [
      _createElementVNode("form", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('common.language')) + " " + _toDisplayString(_ctx.editMode ? '*' : ''), 1),
          _createVNode(_component_Dropdown, {
            id: "language",
            modelValue: _ctx.mutableProfile.language,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.mutableProfile.language) = $event)),
            options: _ctx.languageOptions,
            "option-label": "label",
            "option-value": "value",
            class: _normalizeClass({ 'p-invalid': _ctx.editMode && !_ctx.validatedForm.language.valid, 'no-editable': !_ctx.editMode })
          }, null, 8, ["modelValue", "options", "class"]),
          (_ctx.editMode && !_ctx.validatedForm.language.valid)
            ? (_openBlock(), _createElementBlock("small", _hoisted_5, _toDisplayString(_ctx.$t(`${_ctx.validatedForm.language.message}`)), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    footerActions: _withCtx(() => [
      _createVNode(_component_Button, {
        class: "p-button-secondary p-button-medium",
        label: _ctx.$t('common.cancel'),
        onClick: _ctx.onCancelEdition
      }, null, 8, ["label", "onClick"]),
      _createVNode(_component_Button, {
        class: "p-button p-button-info p-button-medium",
        loading: _ctx.submiting,
        label: _ctx.$t('common.save'),
        onClick: _ctx.onSubmit
      }, null, 8, ["loading", "label", "onClick"])
    ]),
    _: 1
  }, 8, ["cardTitle", "edit-mode"]))
}